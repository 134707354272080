
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import { Pageniation, Loading } from "@/utils/decorator";
import SwitchStatus from "@/components/scope/switch-status.vue";
import { Table, TableRowsSelect } from "@/utils/decorator";
import t from "@common/src/i18n/t";
import dayjs from "dayjs";
const base = namespace("base");
const service = namespace("service");
const learning = namespace("learning");
@Component({
  components: { SwitchStatus }
})
@Pageniation("loadListData")
@Table("loadListData")
@TableRowsSelect({
  warningMsg: t("setting.choose-payment-method"),
  updateStatusFuncName: "confirmUpdateStatus",
  tableRefKey: "table"
})
export default class ExamLevelList extends Vue {
  @base.Action getDictionaryList;
  @service.Action getOrderList;
  @learning.Action queryLevelList;
  @learning.Action changeLevelOrder;
  @learning.Action updateLevelStatus;
  emptyToLine = emptyToLine;
  dayjs = dayjs;
  splitThousands = splitThousands;
  cardTemplateList: any[] = [];
  defaultExpend: boolean = false;
  //计算属性
  get searchList() {
    return [
      {
        label: t("v210831.level-name"),
        type: "input",
        value: "",
        prop: "levelName",
        placeholder: t("base.please-input")
      }
    ];
  }
  total = undefined;
  searchForm = {
    levelName: ""
  };
  created() {
    this.init();
  }
  mounted() {}
  init() {
    this.loadListData();
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.value;
      }
    });
    this.loadListData();
  }
  @Loading()
  loadListData() {
    return this.queryLevelList({
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      this.cardTemplateList = data.data.list;
      this.total = data.total;
      return data;
    });
  }
  handleEdit(row) {
    this.$router.push({
      name: "examLevelEdit",
      params: {
        row: row
      }
    });
  }
  handleCreate() {
    this.$router.push("/learning/exam/level-edit");
  }
  // 排序
  upOne(scope, num) {
    if (this.searchForm.levelName !== "") {
      this.$message.warning(t("v210831.query-status-does"));
      return;
    }
    let params = {
      currentLevelCode: scope.row.levelCode,
      operateType: "4"
    };
    this.sortArrList(params);
  }
  upTop(scope, num) {
    if (this.searchForm.levelName !== "") {
      this.$message.warning(t("v210831.query-status-does"));
      return;
    }
    let params = {
      currentLevelCode: scope.row.levelCode,
      operateType: "1"
    };
    this.sortArrList(params);
  }
  downOne(scope, num) {
    if (this.searchForm.levelName !== "") {
      this.$message.warning(t("v210831.query-status-does"));
      return;
    }
    let params = {
      currentLevelCode: scope.row.levelCode,
      operateType: "5"
    };
    this.sortArrList(params);
  }
  downBottom(scope, num) {
    if (this.searchForm.levelName !== "") {
      this.$message.warning(t("v210831.query-status-does"));
      return;
    }
    let params = {
      currentLevelCode: scope.row.levelCode,
      operateType: "3"
    };
    this.sortArrList(params);
  }
  sortArrList(params) {
    this.changeLevelOrder(params).then(() => {
      this.$message.success(t("v210831.sorting-succeeded"));
      this.loadListData();
    });
  }
  confirmUpdateStatus(status, selectedRow?) {
    return this.updateLevelStatus({
      status: status,
      levelCode: selectedRow.levelCode
    })
      .then(data => {
        this.$message.success(
          status === 1 ? (t("setting.enable-successful") as string) : (t("setting.disable-success") as string)
        );
        this.loadListData();
      })
      .catch(e => {
        if (e.code === "speedbiz.system.pay-method-least-one-valid") {
          this.$message.error(t("setting.valid-method-payment"));
        }
      });
  }
  handleView() {}
  handelEdit() {}
  //  /////
}
